<template>
    <div>
        <el-dialog :visible.sync="sta.show" fullscreen>
            <!-- <span slot="title">单据详情</span> -->
            <div class="row" v-loading="sta.loading" id="print">
                <el-card style="width:100%; margin-bottom: 20px">
                    <el-divider content-position="center"><span style="color: #3bb667">基础信息</span></el-divider>
                    <el-row>
                        <el-col :span="6">
                            <div class="form-group">
                                <label><b>单号：</b></label>
                                {{ model.SN }}
                            </div>
                        </el-col>
                        <el-col :span="18">
                            <div class="form-group">
                                <label><b>标题：</b></label>
                                {{ model.TITLE }}
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <div class="form-group" style="color: #7e65ff">
                                <label><b>当前步骤：</b></label>
                                {{ model.STEP_DESC }}
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="form-group">
                                <label><b>状态：</b></label>
                                {{ model.STA_TXT }}
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="form-group">
                                <label><b>提交人：</b></label>
                                {{ model.SUB_NAME }}
                            </div>
                        </el-col>
                        <el-col :span="6">
                            <div class="form-group">
                                <label><b>合计金额：</b></label>
                                ￥{{ model.AMT }} 元
                            </div>
                        </el-col>
                    </el-row>
                    <el-table :data="model.gridg" stripe border :cell-style="{ padding: '0px' }" show-summary
                        :summary-method="getSummaries"
                        :header-cell-style="{ borderBottom: 'solid 1px #ccc', backgroundColor: '#f5f5f5', textAlign: 'center', padding: '5px 0px' }">
                        <el-table-column prop="CAT_NAME" label="分类名称" width="150" />
                        <el-table-column prop="ST_NAME" label="物品名称" width="200" />
                        <el-table-column prop="ST_UNIT" label="单位" width="60" align="center" />
                        <el-table-column label="数量" width="80" align="center">
                            <template slot-scope="scope">
                                <el-input type="number" v-model="scope.row.CNT"
                                    v-if="model.STEP_IDX == 201 && model.STA == 10 && model.STEP_TEA_ID == model.UID"
                                    style="margin: 5px 0px; line-height: 16px" />
                                <span v-else>{{ scope.row.CNT }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="金额(元)" width="120" align="right">
                            <template slot-scope="scope">
                                ￥{{ scope.row.AMT.toFixed(2) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="DESC" label="备注" width="264" />
                    </el-table>

                </el-card>
                <el-card style="width:100%; margin-bottom: 20px">
                    <el-divider content-position="center"><span style="color: #3bb667">审核记录</span></el-divider>
                    <el-table :data="model.gridv" stripe border :cell-style="{ padding: '0px' }"
                        :header-cell-style="{ borderBottom: 'solid 1px #ccc', backgroundColor: '#f5f5f5', textAlign: 'center', padding: '5px 0px' }">
                        <el-table-column prop="STEP_NAME" label="步骤" width="80" />
                        <el-table-column prop="TEA_NAME" label="姓名" width="80" align="center" />
                        <el-table-column prop="DESC" label="意见" width="200" />
                        <el-table-column label="时间" width="150">
                            <template slot-scope="scope" align="center">
                                {{ scope.row.VTIME | datetime('YYYY-MM-DD HH: mm') }}</template>
                        </el-table-column>
                        <el-table-column />
                    </el-table>
                </el-card>
                <el-card style="width:100%; margin-bottom: 20px"
                    v-if="model.STA == 10 && model.STEP_TEA_ID > 0 && model.STEP_TEA_ID == model.UID">
                    <el-divider content-position="center"><span style="color: #7e65ff">我的意见</span></el-divider>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label><b>拒绝理由：</b></label><br />
                                <el-input type="text" v-model="model.DESC" placeholder="请输入您的意见" />
                            </div>
                            <!-- <div>
                                <el-button icon="el-icon-video-pause" type="danger" @click="refuse">拒绝</el-button>
                                <el-button icon="el-icon-check" type="success" @click="agree">同意</el-button>
                            </div> -->
                        </div>
                    </div>
                </el-card>
            </div>
            <div slot="footer">
                <el-button type="primary" icon="el-icon-printer" v-print="printObj">打印</el-button>
                <el-button icon="el-icon-close" @click="sta.show = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            sta: {
                show: false,
                loading: false
            },
            model: {},
            printObj: {
                id: 'print',
            }
        }
    },
    mounted() {

    },
    methods: {
        init(id) {
            this.sta = { show: true, loading: false }
            this.model = {};
            let self = this;
            this.whale.remote.getResult({
                url: "/api/School/RENT/RDatApi/Get",
                data: { ID: id },
                completed: function (m) {
                    m.DATA.gridg = JSON.parse(m.DATA.GBODY);
                    m.DATA.gridg.map((o) => {
                        if (!o.RAMT) o.RAMT = o.AMT;
                    })
                    m.DATA.gridv = JSON.parse(m.DATA.VBODY);
                    self.model = m.DATA;
                    let user = self.whale.user.get();
                    self.model.UID = user.ID;
                }
            })
        },
    },
}
</script>

<style media="print">
@media print {
    @page {
        size: landscape
    }
}</style>